<template>
  <div class="auth">
    <div class="auth__header">
      <a href="https://cabinet.leasing-trade.ru/cabinet" class="auth__header__link">Перейти в личный кабинет</a>
      <div class="auth__logo">
        <img src="/logo.png" alt="Лизинг трейд"/>
      </div>
    </div>
    <div class="auth__body">
      <div class="auth__body__main">
        <h1 class="auth__form__title">Активация</h1>
        <div class="auth__body__info">
          <span>{{ infoList.tg.isSuccess ? infoList.tg.successText : infoList.tg.errorText }}</span>
          <span v-if="infoList.tg.isSuccess">Автоматический редирект через 5 секунд</span>
        </div>
      </div>
      <div class="auth__body__back">
        <AuthBack/>
      </div>
    </div>
  </div>
</template>

<script>
import AuthBack from '../components/svg/AuthBack'
import TG_ACTIVATION from '../graphql/mutations/TgActivation.gql'

export default {
  name: 'ActivationAccount',
  data: () => {
    return {
      account: null,
      infoList: {
        tg: {
          successText: 'Телеграм аккаунт успешно привязан к вашему личному кабинету',
          errorText: 'Не удалось привязать телеграм аккаунт к вашему личному кабинету. Возможно, у вас уже привязан телеграм аккаунт. Чтобы его изменить, перейдите в настройки в личном кабинете',
          isSuccess: false
        }
      }
    }
  },
  components: {
    AuthBack
  },
  beforeMount () {
    if (!Object.keys(this.$route.query).includes('tg')) {
      this.$router.push('/')
    }
    this.account = Object.keys(this.$route.query)[0]
    if (!this.account.includes('tg')) {
      this.$router.push('/')
    }
    this.tgAccountActivation()
  },
  watch: {
    'infoList.tg.isSuccess': function (value) {
      if (value) {
        setTimeout(() => {
          this.$router.push({ name: 'cabinet' })
          this.$router.go()
        }, 10000)
      }
    }
  },
  methods: {
    async tgAccountActivation () {
      if (this.$route.query.tg) {
        await this.$apollo.mutate({
          mutation: TG_ACTIVATION,
          variables: {
            token: this.$route.query.tg
          },
          update: (store, { data: { tgActivation } }) => {
            this.infoList.tg.isSuccess = tgActivation
          }
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.auth__body__info {
  display flex
  flex-direction column

  > span {
    font-size 18px
    color #7a7a7a
  }
}
</style>
